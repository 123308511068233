import request from '../util/request';
export function listPeserta(params) {
  return request({
    url: 'auth/listPeserta',
    method: 'get',
    params: params
  });
}

export function listDokumenPeserta(params) {
  return request({
    url: 'auth/listDokumenPeserta/'+params,
    method: 'get'
  });
}

export function verifikasi(params) {
  return request({
    url: 'auth/verifikasiAdmin',
    method: 'post',
    data: params
  });
}

export function viewPdfAdmin(data) {
  return request({
    url: 'auth/viewPdfAdmin',
    method: 'get',
    params: data,
    responseType: 'blob'
  });
}