<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Persyaratan Peserta</h4>
    </div>
    <div class="col-md-12 card">
      <!-- <div class="card-header">
        <div class="category">Extended tables</div>
      </div> -->
      <div class="card-body row">
        <b-overlay :show="isLoad" rounded="sm">
          <b-row>
            <b-col xxs="12">
              <b-card >
                <b-card-text>
                  <h1>Data Peserta</h1>
                </b-card-text>
                <b-container class="bv-example-row" fluid>
                  <b-row class="text-left">
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="NIP"
                      >
                        <b-form-input v-model="nip"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        id="fieldset-1"
                        label="Nama"
                      >
                        <b-form-input v-model="nama"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="text-left">
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Jabatan"
                      >
                        <b-form-textarea
                          id="textarea"
                          v-model="jabatan"
                          rows="3"
                          max-rows="3"
                          readonly
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        id="fieldset-1"
                        label="Organisasi"
                      >
                      <b-form-textarea
                          id="textarea"
                          v-model="organisasi"
                          rows="3"
                          max-rows="3"
                          readonly
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="text-left">
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Masa Kerja"
                      >
                        <b-form-input v-model="masa_kerja"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Pangkat Golongan"
                      >
                        <b-form-input v-model="pangkat_gol"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="text-left">
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Lokasi Test"
                      >
                        <b-form-input v-model="lokasi_test"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Jabatan Yang di lamar"
                      >
                        <b-form-input v-model="jabatan_yang_dilamar"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="text-left">
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Nomor Handphone"
                      >
                        <b-form-input v-model="no_hp"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>  
                      <b-form-group
                        id="fieldset-1"
                        label="Email"
                      >
                        <b-form-input v-model="email"  readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
              <b-table-simple hover small caption-top responsive bordered striped>
                <b-thead head-variant="dark">
                  <b-tr > 
                    <b-th>No</b-th>
                  
                    <b-th width="40%">Syarat</b-th>
                    <b-th width="15%">Data</b-th>
                    <b-th width="10%">Status Verifikasi</b-th>
                    <b-th width="10%">Verifikasi Berkas</b-th>
                    <b-th width="20%">Keterangan</b-th>
                    <b-th width="20%">Aksi</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr >
                    <b-th >1</b-th>
                    <b-th >
                      Surat lamaran yang dibubuhi meterai Rp.10.000 <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="suratLamaran.file != '' && suratLamaran.file != null " type="button" variant="primary" class="mt-4" @click="lihat(1)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ suratLamaran.verifikasi }} - {{ suratLamaran.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="suratLamaran.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="suratLamaran.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(1)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >2</b-th>
                    <b-th >
                      Ijazah Asli yang dipersyaratkan   <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="ijazah.file != '' && ijazah.file != null " type="button" variant="primary" class="mt-4" @click="lihat(2)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ ijazah.verifikasi }} -  {{ ijazah.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="ijazah.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="ijazah.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(2)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >3</b-th>
                    <b-th >
                      Surat Keputusan pengangkatan dalam jabatan terakhir   <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="sk_pengangkatan_akhir.file != '' && sk_pengangkatan_akhir.file != null " type="button" variant="primary" class="mt-4" @click="lihat(3)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ sk_pengangkatan_akhir.verifikasi }} - {{ sk_pengangkatan_akhir.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="sk_pengangkatan_akhir.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="sk_pengangkatan_akhir.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(3)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >4</b-th>
                    <b-th >
                      Surat keterangan memiliki rekam jejak jabatan, integritas, dan moralitas yang ditandatangani oleh <b>Pejabat Pembina Kepegawaian atau Pejabat yang Berwenang</b> <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="suker_rekam_jejak.file != '' && suker_rekam_jejak.file != null " type="button" variant="primary" class="mt-4" @click="lihat(4)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ suker_rekam_jejak.verifikasi }} - {{ suker_rekam_jejak.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="suker_rekam_jejak.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="suker_rekam_jejak.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(4)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >5</b-th>
                    <b-th >
                      Penilaian Prestasi Kerja 2 (dua) tahun terakhir setiap unsur minimal bernilai <b>baik</b>, tahun 2020 dan tahun 2021 <font color="red">(tipe file : pdf, max : 1500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="penilaian_prestasi.file != '' && penilaian_prestasi.file != null " type="button" variant="primary" class="mt-4" @click="lihat(5)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ penilaian_prestasi.verifikasi }} - {{ penilaian_prestasi.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="penilaian_prestasi.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="penilaian_prestasi.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(5)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >6</b-th>
                    <b-th >
                      Tanda bukti penyerahan LHKPN/LHKASN tahun 2021 <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="lhkpn.file != '' && lhkpn.file != null " type="button" variant="primary" class="mt-4" @click="lihat(6)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ lhkpn.verifikasi }} -  {{ lhkpn.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="lhkpn.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="lhkpn.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(6)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >7</b-th>
                    <b-th >
                      Tanda bukti penyerahan Surat Pemberitahuan Tahunan (SPT) Pajak tahun 2020 dan 2021 <font color="red">(tipe file : pdf/jpeg/jpg, max : 500 Kb)</font> Contoh SPT : 
                      <!-- <a href="#"  v-on:click="downloadSPT()">Donwload SPT</a> -->
                    </b-th>
                    <b-th >
                      <b-button v-if="tanda_bukti_spt.file != '' && tanda_bukti_spt.file != null " type="button" variant="primary" class="mt-4" @click="lihat(7)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ tanda_bukti_spt.verifikasi }} -  {{ tanda_bukti_spt.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="tanda_bukti_spt.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="tanda_bukti_spt.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(7)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >8</b-th>
                    <b-th >
                      Surat persetujuan/rekomendasi untuk melamar dari <b>Pejabat Pembina Kepegawaian atau Pejabat lain yang diberikan mandat/kewenangan </b><font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="surat_rekomendasi.file != '' && surat_rekomendasi.file != null " type="button" variant="primary" class="mt-4" @click="lihat(8)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ surat_rekomendasi.verifikasi }} -  {{ surat_rekomendasi.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="surat_rekomendasi.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="surat_rekomendasi.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(8)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >9</b-th>
                    <b-th >
                      Surat pernyataan tidak sedang dalam proses pemeriksaan pelanggaran disiplin dan/atau tidak pernah menjalani hukuman disiplin sesuai dengan ketentuan peraturan perundang-undangan yang berlaku selama 2 (dua) tahun terakhir, dan tidak sedang dalam proses peradilan pidana dari <b> Pejabat Pembina Kepegawaian atau Pejabat Pimpinan Tinggi Pratama yang membidangi kepegawaian </b><font color="red">tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="surat_pernyataan_tidak_sedang_hukdis.file != '' && surat_pernyataan_tidak_sedang_hukdis.file != null " type="button" variant="primary" class="mt-4" @click="lihat(9)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ surat_pernyataan_tidak_sedang_hukdis.verifikasi }} -  {{ surat_pernyataan_tidak_sedang_hukdis.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="surat_pernyataan_tidak_sedang_hukdis.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="surat_pernyataan_tidak_sedang_hukdis.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(9)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >10</b-th>
                    <b-th >
                      Sertifikat Diklat Kepemimpinan/Diklat Fungsional dan Diklat Teknis yang sesuai dengan jabatan yang akan dilamar  <font color="red">(tipe file : pdf, max : 6000 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="sertifikasi_pelatihan.file != '' && sertifikasi_pelatihan.file != null " type="button" variant="primary" class="mt-4" @click="lihat(10)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ sertifikasi_pelatihan.verifikasi }} - {{ sertifikasi_pelatihan.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="sertifikasi_pelatihan.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="sertifikasi_pelatihan.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(10)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >11</b-th>
                    <b-th >
                      Daftar riwayat hidup  <font color="red">(tipe file : pdf, ma x : 1500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-button v-if="formulir_drh.file != '' && formulir_drh.file != null " type="button" variant="primary" class="mt-4" @click="lihat(11)">Lihat Data</b-button>
                      <p v-else >Belum ada data</p>
                    </b-th>
                    <b-th >
                      {{ formulir_drh.verifikasi }} - {{ formulir_drh.keterangan }}
                    </b-th>
                    <b-th >
                      <b-form-select v-model="formulir_drh.verifikasiAdmin" :options="options" size="sm" class="mt-3"></b-form-select>
                    </b-th>
                    <b-th >
                      <b-form-input v-model="formulir_drh.keteranganAdmin"></b-form-input>
                    </b-th>
                    <b-th >
                      <b-button variant="success" @click="verifikasi(11)">Verifikasi</b-button>
                    </b-th>
                  </b-tr>

                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
         
		    </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
  
  import { listDokumenPeserta, verifikasi, viewPdfAdmin } from "../../../api/admin"
  import request from '../../../util/request';
  export default{
    data () {
      return {
        nip: '',
        nama: '',
        jabatan: '',
        organisasi: '',
        lokasi_test: '',
        jabatan_yang_dilamar: '',
        masa_kerja: '',
        pangkat_gol: '',
        email: '',
        no_hp:'',
        fileUpload: null,
        isLoad: false,
        modalShow: false,
        percent: 0,
        viewPdf: '',
        error: '',
        data: {
          id_personal: this.$route.params.id,
          id_syarat: '',
          verifikasi: '',
          keterangan: ''
        },
        options: [
          { value: '', text: '' },
          { value: 'Valid', text: 'Valid' },
          { value: 'Ditolak', text: 'Ditolak' },
          { value: 'Belum Verifikasi', text: 'Belum Verifikasi' },
        ],
        suratLamaran: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        ijazah: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        sk_pengangkatan_akhir: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        suker_rekam_jejak: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        penilaian_prestasi: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        lhkpn: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        tanda_bukti_spt: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        surat_rekomendasi: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        surat_pernyataan_tidak_sedang_hukdis: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        sertifikasi_pelatihan: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        },
        formulir_drh: {
          file: '',
          verifikasi: '',
          keterangan: '',
          verifikasiAdmin: '',
          keteranganAdmin: ''
        }
        
      }
    },
    created() {
      this.showData();
    },
    methods: {
      showData(){
        this.isLoad = true
        listDokumenPeserta(this.$route.params.id)
        .then((response) => {
          this.isLoad = false;
          this.suratLamaran.file = response[0].file;
          this.suratLamaran.verifikasi = response[0].verifikasi;
          this.suratLamaran.keterangan = response[0].keterangan;

          this.ijazah.file = response[1].file;
          this.ijazah.verifikasi = response[1].verifikasi;
          this.ijazah.keterangan = response[1].keterangan;

          this.sk_pengangkatan_akhir.file = response[2].file;
          this.sk_pengangkatan_akhir.verifikasi = response[2].verifikasi;
          this.sk_pengangkatan_akhir.keterangan = response[2].keterangan;

          this.suker_rekam_jejak.file = response[3].file;
          this.suker_rekam_jejak.verifikasi = response[3].verifikasi;
          this.suker_rekam_jejak.keterangan = response[3].keterangan;

          this.penilaian_prestasi.file = response[4].file;
          this.penilaian_prestasi.verifikasi = response[4].verifikasi;
          this.penilaian_prestasi.keterangan = response[4].keterangan;

          this.lhkpn.file = response[5].file;
          this.lhkpn.verifikasi = response[5].verifikasi;
          this.lhkpn.keterangan = response[5].keterangan;

          this.tanda_bukti_spt.file = response[6].file;
          this.tanda_bukti_spt.verifikasi = response[6].verifikasi;
          this.tanda_bukti_spt.keterangan = response[6].keterangan;

          this.surat_rekomendasi.file = response[7].file;
          this.surat_rekomendasi.verifikasi = response[7].verifikasi;
          this.surat_rekomendasi.keterangan = response[7].keterangan;

          this.surat_pernyataan_tidak_sedang_hukdis.file = response[8].file;
          this.surat_pernyataan_tidak_sedang_hukdis.verifikasi = response[8].verifikasi;
          this.surat_pernyataan_tidak_sedang_hukdis.keterangan = response[8].keterangan;

          this.sertifikasi_pelatihan.file = response[9].file;
          this.sertifikasi_pelatihan.verifikasi = response[9].verifikasi;
          this.sertifikasi_pelatihan.keterangan = response[9].keterangan;

          this.formulir_drh.file = response[10].file;
          this.formulir_drh.verifikasi = response[10].verifikasi;
          this.formulir_drh.keterangan = response[10].keterangan;

          this.formulir_drh.file = response[10].file;
          this.formulir_drh.verifikasi = response[10].verifikasi;
          this.formulir_drh.keterangan = response[10].keterangan;

          this.nip = response[10].nip;
          this.nama = response[10].nama;
          this.jabatan = response[10].jabatan;
          this.organisasi = response[10].organisasi;
          this.lokasi_test = response[10].lokasi;
          this.jabatan_yang_dilamar = response[10].jenis;
          this.masa_kerja = response[10].masa_kerja;
          this.pangkat_gol = response[10].pangkat_gol;
          this.no_hp = response[10].no_hp;
          this.email = response[10].email;
        }).catch((error) => {
          this.isLoad = false;
          console.log(error)
        });
      },  
      
      lihat(data){
        const params = { tipe : data, id: this.$route.params.id}
        viewPdfAdmin(params)
          .then(response => {
            const file = new Blob(
            [response],
            { type: 'application/pdf' })
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            // Open the URL on new Window
            window.open(fileURL, '_blank')		
          })
          .catch(error => {
            this.$notify('error',"Error", error, { duration: 3000, permanent: false });
            // console.log(error)
          })
		  },

    verifikasi(id) {
      this.data.id_syarat = id;
      this.isLoad = true;
     
      switch(id) {
          case 1:
            this.data.verifikasi = this.suratLamaran.verifikasiAdmin
            this.data.keterangan = this.suratLamaran.keteranganAdmin
            break;
          case 2:
            this.data.verifikasi = this.ijazah.verifikasiAdmin
            this.data.keterangan = this.ijazah.keteranganAdmin
            break;
          case 3:
            this.data.verifikasi = this.sk_pengangkatan_akhir.verifikasiAdmin
            this.data.keterangan = this.sk_pengangkatan_akhir.keteranganAdmin
            break;
          case 4:
            this.data.verifikasi = this.suker_rekam_jejak.verifikasiAdmin
            this.data.keterangan = this.suker_rekam_jejak.keteranganAdmin
            break;
          case 5:
            this.data.verifikasi = this.penilaian_prestasi.verifikasiAdmin
            this.data.keterangan = this.penilaian_prestasi.keteranganAdmin
            break;
          case 6:
            this.data.verifikasi = this.lhkpn.verifikasiAdmin
            this.data.keterangan = this.lhkpn.keteranganAdmin
            break;
          case 7:
            this.data.verifikasi = this.tanda_bukti_spt.verifikasiAdmin
            this.data.keterangan = this.tanda_bukti_spt.keteranganAdmin
            break;
          case 8:
            this.data.verifikasi = this.surat_rekomendasi.verifikasiAdmin
            this.data.keterangan = this.surat_rekomendasi.keteranganAdmin
            break;
          case 9:
            this.data.verifikasi = this.surat_pernyataan_tidak_sedang_hukdis.verifikasiAdmin
            this.data.keterangan = this.surat_pernyataan_tidak_sedang_hukdis.keteranganAdmin
            break;
          case 10:
            this.data.verifikasi = this.sertifikasi_pelatihan.verifikasiAdmin
            this.data.keterangan = this.sertifikasi_pelatihan.keteranganAdmin
            break;
          case 11:
            this.data.verifikasi = this.formulir_drh.verifikasiAdmin
            this.data.keterangan = this.formulir_drh.keteranganAdmin
            break;
          default:
            // code block
        }
        console.log(this.data);
        if(this.data.verifikasi == '' || this.data.keterangan == ''){
          this.$notify({
                icon: "nc-icon nc-bell-55",
                message: "Keterangan dan Verifikasi Tidak Boleh Kosong",
                type: "danger",
              });
              this.isLoad = false;
        } else {
          verifikasi(this.data).then((res)=>{
            this.isLoad = false;
            this.showData();
            
          }).catch((error)=> {
            this.isLoad = false;
            this.$notify({
                icon: "nc-icon nc-bell-55",
                message: "Ada Kesalahan",
                type: "danger",
              });
          })
        }
    
    },
      
    }
  }
</script>
