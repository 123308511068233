<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">List Peserta</h4>
    </div>
    <div class="col-md-12 card">
      <!-- <div class="card-header">
        <div class="category">Extended tables</div>
      </div> -->
      <div class="card-body row">
        <b-overlay :show="isLoad" rounded="sm">
          <b-row>
            <b-col xxs="12">
              <b-table-simple hover small caption-top responsive bordered striped>
                <b-thead head-variant="dark">
                  <b-tr >
                    <b-th>No.</b-th> 
                    <b-th>NIP</b-th>
                    <b-th>Nama</b-th>
                    <b-th>Jabatan</b-th>
                    <b-th>Posisi yang Dilamar</b-th>
                    <b-th>Jumlah Upload/Total</b-th>
                    <b-th>Aksi</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(item,index1) in items" :key="index1" >
                    <b-th >{{ index1 + 1}}</b-th>
                    <b-th >{{item.nip}}</b-th>
                    <b-th >{{item.nama}}</b-th>
                    <b-th >{{item.jabatan}}</b-th>
                    <b-th >{{item.jenis}}</b-th>
                    <b-th >{{item.jumlahupload}}</b-th>
                    <b-th >    
                      <b-button-group class="mb-2" size="sm">
                        <b-button  variant="success" @click="edit(item.id)" title="Edit" ><div :class="'glyph-icon iconsminds-file-edit'"/>Verifikasi</b-button>
                      </b-button-group>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
            <b-row>
            <b-col>
              <b-pagination
                v-model="data.page"
                :total-rows="data.total"
                :per-page="data.per_page"
                align="center"
                size="sm"
                class="my-0"
                first-text="First"
                last-text="Last"
              ></b-pagination>
            </b-col>
          </b-row>
		    </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
  import { listPeserta } from "../../../api/admin"
  export default{
    
    computed: {
      page(){
        return this.data.page;
      },
    },
    watch: {
      page: {
        handler: function() {
          this.showData();
        }
      }
    },
    data () {
      return {
        isLoad: false,
          data : {
            page: 1,
            per_page: 30,
            search: "",
            from: 0,
            to: 0,
            total: 0,
            lastPage: 0,
          },
          items:[],
      }
    },
    created() {
      this.showData();
    },
    methods: {
      showData(){
        this.isLoad = true
        listPeserta(this.data)
        .then((response) => {
          this.isLoad = false;
          this.items = response.data;
          this.jumlah = response.jumlah;
          this.data.total = response.total;

          console.log(data)
          this.isLoad = false
        }).catch((error) => {
          console.log(error)
          this.isLoad = false
        });
      },  
      edit(id){
        this.$router.push({ name: "ListDokumenPeserta", params: { id: id } });
      }
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
</style>
